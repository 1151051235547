import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const FeaturedDestinationPage = () => {
  return (
    <Layout>
      <Seo 
        title="Featured Destination" 
        description="EV charging concierge for destinations"
      />
    </Layout>
  )
}

export default FeaturedDestinationPage
